import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Hero } from '../../ui/hero/Index';
import { Heading } from '../../ui/text/Heading';
import { SharingVcard } from '../broker/ui/SharingVcard';
import { Employee } from '../../../__types__/generated/cluster';
import { formatPhone } from '../../../utils/format/formatPhone';

const MeglerTopStyle = styled.div`
  /* Desktop */
  position: relative;
  text-align: center;

  .content-height-custom {
    height: auto;
    min-height: 280px;
    align-items: start;
  }

  .top-container {
    //position: absolute;
    //top: 0;
  }

  @media all and (min-width: 900px) {
    .content-height-custom {
      height: auto;
      min-height: 500px;
      align-items: center;
    }
    .top-container {
      //top: auto;
      //bottom: 0;
    }
  }

  .hero-content {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 2em;

    h2 {
      //font-size: 68px;
      margin: 0;
    }

    p {
      margin: 0;
      :not(:first-of-type) {
        margin-top: 0.4em;
      }
    }

    .champagne {
      color: ${({ theme }) => theme.colors.primary};
    }

    .contact {
      padding-top: 1.5em;
      @media all and (min-width: 900px) {
        padding-top: 2em;
      }
      .email {
        color: white;
      }
    }

    @media all and (min-width: 900px) {
      margin-top: -2em;

      h2 {
        font-size: 4em;
        line-height: 89px;
      }
    }
  }

  &.flex {
    @media all and (min-width: 900px) {
      .content-height-custom {
        align-items: flex-start;
      }
      .hero-content {
        margin-top: 2.5rem;
      }
    }
  }
`;

interface IArgs {
  backgroundImage?: string | null;
  employee?: Partial<Employee>;
}

const MeglerTop: React.FC<IArgs> = ({
  backgroundImage,
  employee,
  children
}) => {
  const params = useParams<{ path: string }>();
  const [office, SetOffice] = React.useState(
    employee?.departments
      ?.filter((e) => e?.name)
      ?.find((e) => {
        return e?.name;
      })
  );

  React.useEffect(() => {
    if (employee?.departments?.length) {
      SetOffice(
        employee?.departments
          ?.filter((e) => e?.name)
          ?.find((e) => {
            return e?.name;
          })
      );
    }
  }, [employee?.departments]);

  return (
    <MeglerTopStyle>
      <Hero
        className="content-height-custom flex padding-top-50"
        opacity={1}
        alt="bilde"
        imageUrl={
          backgroundImage
            ? backgroundImage
            : 'https://cdn.reeltime.no/pm_assets/meglercv/PMcv_topp_shinypattern.jpg'
        }
      >
        <Container className={`top-container`}>
          <WrapperOne className="top-info">
            <Heading tag="h2" className="name">
              {employee?.name}
            </Heading>
            {employee?.title ? <p>{employee?.title}</p> : null}
            {office?.name ? (
              <p className="champagne">
                {office?.alias ? (
                  <a href={`/${office?.alias}`}>
                    PrivatMegleren {office?.name}
                  </a>
                ) : (
                  `Privatmegleren ${office?.name}`
                )}
              </p>
            ) : null}
            {employee?.mobilePhone?.trim()?.length ||
            employee?.email?.trim()?.length ? (
              <>
                <div className="contact">
                  {employee?.mobilePhone?.trim()?.length ? (
                    <p>{formatPhone(employee?.mobilePhone?.trim())}</p>
                  ) : null}
                  {employee?.email?.trim() ? (
                    <p>
                      <a
                        className="email"
                        href={`mailto:${employee?.email?.trim()}`}
                      >
                        {employee?.email?.trim()}
                      </a>
                    </p>
                  ) : null}
                </div>
              </>
            ) : null}
            {!(employee?.name && employee?.employeeAlias) ? null : (
              <SharingVcardContainer>
                <SharingVcard
                  className="v-card"
                  name={employee?.name}
                  title={employee?.title ? employee?.title : ''}
                  path={employee?.employeeAlias}
                  showTitles={true}
                  showCards={['download', 'share']}
                />
              </SharingVcardContainer>
            )}
          </WrapperOne>
        </Container>
      </Hero>
    </MeglerTopStyle>
  );
};

const Container = styled.div`
  display: flex;

  &.relative {
    padding-top: 2rem;
  }
`;

const WrapperOne = styled.div`
  text-align: left;
  margin-left: 2em;

  .name {
    &::first-letter {
      font-family: 'Kondo', sans-serif;
      font-size: 140%;
    }
  }

  @media all and (min-width: 600px) {
    text-align: left;
    margin-left: 9em;

    .top-info {
      padding-top: 5em;
      padding-left: 3em;
    }
  }

  @media all and (min-width: 600px) and (max-width: 900px) {
    text-align: left;
    margin-left: 3em;
  }
`;

const SharingVcardContainer = styled.div`
  /* mobile first */
  margin-bottom: 20px;
  .v-card {
    padding-top: 0.5rem;
    justify-content: start;
    a {
      padding-left: 0;
    }
  }

  @media all and (min-width: 600px) {
    .v-card {
    }
  }

  @media all and (min-width: 900px) {
    position: absolute;
    margin-bottom: 0;

    &.flex {
      position: relative;
      margin-bottom: 20px;
    }
  }
`;

export default MeglerTop;
